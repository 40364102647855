import React from "react";

export const MapLocation = () => (
  <div className="my-8 md:my-12 h-[250px] sm:h-[350px] lg:h-[500px]">
    <iframe
      title="Address"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.161319608558!2d-74.0011720150022!3d40.736475697620975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25938111d3cdb%3A0x8b732a7f209c1ecc!2s47%20W%2013th%20St%2C%20New%20York%2C%20NY%2010011%2C%20USA!5e0!3m2!1sen!2s!4v1717319707666!5m2!1sen!2s"
      width="100%"
      height="100%"
      style={{ border: 0 }}
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
);
