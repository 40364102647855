import React from "react";
import { mImages } from "../../../assets/images";
import { PrimaryButton } from "../../common";

export const HomeCommunitiesBlock = () => (
  <section className={"py-12 lg:py-16 bg-transparent px-5 sm:px-0"}>
    <div className="resContainer">
      <h1
        className={
          "text-[22px] sm:text-[28px] uppercase md:text-[40px] font-normal text-dark text-center"
        }
      >
        COMMUNITIES
      </h1>
      <p className={"text-sm sm:text-base text-gray-700 mt-2 text-center"}>
        Explore the places, neighborhoods, and people that make the communities
        we serve special.
      </p>
      <div className="grid grid-cols-1 mt-10 gap-3 sm:gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <Card title={"Weston"} image={mImages.weston} />
        <Card title={"Pembroke pines"} image={mImages.pemrocPines} />
        <Card title={"Davie"} image={mImages.davie} />
        <Card title={"Orlando"} image={mImages.orlando} />
        <Card title={"Miami"} image={mImages.miami} />
        <Card title={"Parm Beach"} image={mImages.parmBeach} />
      </div>
      <PrimaryButton
        title="View all"
        className="max-w-[140px] block mx-auto mt-5 sm:mt-10"
      />
    </div>
  </section>
);

// Card
const Card = ({ title, image }) => (
  <div className="w-full h-[300px] relative group overflow-hidden ">
    <img
      src={image}
      alt="Buy"
      className="w-full h-full object-cover group-hover:scale-110 transition-all duration-700"
    />
    <div className="w-full h-full flex justify-center items-center px-5 absolute bottom-0 left-0 bg-black/50">
      <div className="group-hover:-translate-y-10 transition-all duration-500 flex flex-col items-center gap-y-4 mt-7 sm:mt-16">
        <h2 className="text-2xl font-medium text-white uppercase">{title}</h2>
        <button className="h-[44px] uppercase px-4 border-2 rounnded-sm text-sm font-medium text-white opacity-0 group-hover:opacity-100 transition-all duration-500 hover:bg-white hover:text-primary">
          Learn More
        </button>
      </div>
    </div>
  </div>
);
