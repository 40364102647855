import headerBg from "./header-bg.jpg";
import homeHeaderBg from "./bg.jpg";
import property from "./property.png";
import headerLogo from "./header-logo.png";
import footerLogo from "./footer-logo.png";
import agentProfile from "./profile.jpg";
import buy from "./buy.jpg";
import sell from "./sell.jpg";
import rent from "./rent.jpg";
import davie from "./davie.jpg";
import miami from "./miami.jpg";
import orlando from "./orlando.jpg";
import weston from "./weston.jpg";
import parmBeach from "./parm-beach.jpg";
import pemrocPines from "./pemroc-pines.jpg";

export const mImages = {
  headerBg,
  homeHeaderBg,
  property,
  headerLogo,
  footerLogo,
  agentProfile,
  buy,
  sell,
  rent,
  davie,
  miami,
  orlando,
  weston,
  parmBeach,
  pemrocPines,
};
