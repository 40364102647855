import React, { useState, useEffect } from "react";
import { HiMiniBars3 } from "react-icons/hi2";
import { MdOutlineCancel } from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";
import { PrimaryButton } from "../../common";
import { mImages } from "../../../assets/images";
import { menu } from "../../../utils/data";
import { routes } from "../../../utils/contants";

export const Navbar = ({ linksStyles = "" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  let location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`transition-all duration-500 ${
        isSticky && "fixed top-0 w-full bg-white z-[99] shadow-xl left-0"
      }`}
    >
      <nav
        className={`${
          isSticky && "resContainer mx-auto"
        } flex justify-between items-center py-5 md:py-6`}
      >
        <NavLink to={routes.home}>
          <img
            src={mImages.footerLogo}
            alt="Logo"
            width={120}
            className="w-[90px] sm:w-[120px]"
          />
        </NavLink>
        <button
          className="block ml-auto mr-3 sm:mr-5"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <HiMiniBars3 className="text-3xl text-white block md:hidden" />
        </button>
        <div
          className={`transition-all duration-500 p-5 w-full left-0 top-0 fixed h-screen md:h-auto md:p-0 md:relative ${
            isOpen
              ? "translate-x-0 opacity-100 visible bg-black/95"
              : "-translate-x-full opacity-0 invisible md:translate-x-0 md:opacity-100 md:visible"
          }`}
        >
          <button
            className="block md:hidden absolute top-5 right-7"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <MdOutlineCancel className="text-3xl text-white" />
          </button>
          <ul
            className={`flex gap-x-8 items-center justify-center ${
              isOpen ? "flex-col gap-y-8 justify-center h-full md:flex-row" : ""
            }`}
          >
            {menu.map((item, index) => {
              const isActive = location.pathname === item.path;

              return (
                <li key={item.name + index}>
                  <NavLink to={item.path}>
                    <span
                      className={`${
                        isActive
                          ? "text-primary"
                          : `${
                              location.pathname === "/" && !isSticky
                                ? "text-white"
                                : "text-gray-700"
                            }`
                      } text-base font-medium uppercase hover:text-primary`}
                    >
                      {item.name}
                    </span>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
        <NavLink
          to={routes.contactUs}
          className="min-w-[95px] sm:min-w-[120px]"
        >
          <PrimaryButton title="Reach Us" className="w-full" />
        </NavLink>
      </nav>
    </div>
  );
};
