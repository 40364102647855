import React, { useState } from "react";
import { InputField, PrimaryButton, TextAreaField } from "../../common";
import { sendMail } from "../../../utils/sendMail";

export const ContactUsForm = () => {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [payload, setPayload] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  // Send message handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    await sendMail(payload, setSuccessMessage);
    setLoading(false);
    setPayload({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  // Handle Change function
  const handleChange = (e) => {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-y-1.5 sm:gap-y-3">
        <InputField
          label="Name"
          placeholder="Your Name"
          required
          name="name"
          value={payload.name}
          onChange={handleChange}
        />
        <InputField
          type="email"
          label="Email"
          placeholder="Your Email"
          required
          name="email"
          value={payload.email}
          onChange={handleChange}
        />
        <InputField
          type="tel"
          label="Phone Number"
          placeholder="Your phone number"
          required
          name="phone"
          value={payload.phone}
          onChange={handleChange}
        />
        <TextAreaField
          rows={6}
          label="Message"
          placeholder="Your Message"
          required
          name="message"
          value={payload.message}
          onChange={handleChange}
        />
        {successMessage && (
          <p className="text-green-500 text-sm sm:text-base font-medium">
            {successMessage}
          </p>
        )}
        <PrimaryButton
          title="Send Message"
          className="max-w-[160px]"
          type="submit"
          loading={loading}
          disabled={loading}
        />
      </div>
    </form>
  );
};
