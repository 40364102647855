import React from "react";

export const Statistics = () => (
  <div className="flex max-w-[980px] mx-auto flex-col gap-y-8 sm:flex-row w-full items-center py-9 md:py-20 justify-between">
    <Statistic title="Dream Homes Sold" value="2000+" />
    <span className="h-[80px] hidden md:h-[140px] border-l border-gray-700 sm:block" />
    <Statistic title="Volume" value="$100 MILLION" />
    <span className="h-[80px] hidden md:h-[140px] border-l border-gray-700 sm:block" />
    <Statistic title="Years of Experience" value="33+ years" />
  </div>
);

const Statistic = ({ title, value }) => (
  <div className="flex flex-col gap-y-2 md:gap-y-4 items-center">
    <h3 className="text-xl md:text-3xl text-gray-700 font-medium">{value}</h3>
    <p className="text-sm md:text-lg text-gray-700">{title}</p>
  </div>
);
