import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Socials } from "../../layout";
import { mImages } from "../../../assets/images";

export const AboutUsDetails = () => (
  <div className="p-7 md:p-10 bg-white mt-20">
    <div className="flex justify-between items-start">
      <div className="flex gap-x-6">
        <img
          src={mImages.agentProfile}
          alt="Agent"
          className="w-[170px] h-[170px] object-cover object-top -mt-16"
        />
        <div>
          <div className="flex gap-x-1 items-center">
            <h4 className="text-2xl font-bold text-gray-700">
              Jean Samuel Luxama
            </h4>
            <FaCheckCircle className="text-2xl text-primary" />
          </div>
          <p className="text-sm text-gray-700 font-medium mt-2">
            License Real Estate{" "}
            <span className="text-primary font-bold">Agent</span> & Loan
            Originator
          </p>
        </div>
      </div>
      <div className="mt-3">
        <Socials />
      </div>
    </div>

    <div className="mt-12 max-w-[900px]">
      <div>
        <h5 className="text-gray-800 text-xl font-semibold">About</h5>
        <div className="flex flex-col gap-y-4 mt-4">
          <p className="text-sm sm:text-base text-gray-700 sm:leading-7 ">
            My name is Jean Samuel Luxama, and I am a dedicated and experienced
            real estate professional from Haiti. With over a decade of
            experience in sales, I have built a multifaceted career in the real
            estate industry as a real estate agent, mortgage loan originator,
            notary public loan signing agent, property manager, and real estate
            investor. Fluent in English, French, and Creole, I am well-equipped
            to assist a diverse range of clients with their real estate needs in
            Florida.
          </p>
          <p className="text-sm sm:text-base text-gray-700 sm:leading-7 ">
            I am proud to be a part of the Leo Realty team, a well-established
            firm with over 33 years in the business, boasting more than $100
            million in sales volume and over 2000 transactions. As a member of
            this esteemed team, I focus on building strong relationships with
            clients, guided by a core set of values:
          </p>
          <ul className="list-disc ml-10">
            <li className="text-sm sm:text-base text-gray-700 mb-3 font-medium">
              Timeliness
            </li>
            <li className="text-sm sm:text-base text-gray-700 mb-3 font-medium">
              Fidelity
            </li>
            <li className="text-sm sm:text-base text-gray-700 mb-3 font-medium">
              Respect
            </li>
            <li className="text-sm sm:text-base text-gray-700 mb-3 font-medium">
              Honesty
            </li>
            <li className="text-sm sm:text-base text-gray-700 mb-3 font-medium">
              Enjoyment
            </li>
            <li className="text-sm sm:text-base text-gray-700 mb-3 font-medium">
              Compassion
            </li>
          </ul>
          <p className="text-sm sm:text-base text-gray-700 sm:leading-7 ">
            At Leo Realty, we believe that people, not properties, are the
            greatest asset. This client-centered approach ensures that each
            interaction is marked by exceptional service and genuine care. My
            commitment to these values is evident in every aspect of my work,
            from going the extra mile for clients to fostering a supportive and
            collaborative environment with colleagues and vendors.
          </p>
          <p className="text-sm sm:text-base text-gray-700 sm:leading-7 ">
            In addition to my role at Leo Realty, I am part of the KLE Mortgage
            team, where I excel as a mortgage loan originator. My expertise in
            real estate allows me to identify and accurately position leading
            properties within the market, providing clients with unparalleled
            service and insight.
          </p>
          <p className="text-sm sm:text-base text-gray-700 sm:leading-7 ">
            I am not just a real estate specialist; I am the key to all your
            real estate needs. Whether you are buying, selling, or investing,
            you can trust me to provide knowledgeable, compassionate, and
            professional assistance every step of the way.
          </p>
        </div>
      </div>
    </div>
  </div>
);
