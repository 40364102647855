import React, { useState, useEffect, useRef } from "react";
import { Modal, PrimaryButton } from "../../common";
import "../../../styles/search.css";

export const QuickSearchModal = () => {
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const scriptContainerRef = useRef(null);

  const addScript = () => {
    // Remove any existing script
    const existingScript = document.getElementById("idxwidgetsrc-35131");
    if (existingScript) {
      existingScript.remove();
    }

    // Create and append the new script
    const script = document.createElement("script");
    script.charset = "UTF-8";
    script.type = "text/javascript";
    script.src =
      "//jeansamuelluxama.idxbroker.com/idx/quicksearchjs.php?widgetid=43178";
    script.id = "idxwidgetsrc-43178";
    if (scriptContainerRef.current) {
      scriptContainerRef.current.appendChild(script);
    }
  };

  useEffect(() => {
    document.getElementById("openSearchBox")?.click();
  }, [isOpen]);

  return (
    <>
      <PrimaryButton
        title="Find your next home "
        className="max-w-[190px] bg-secondary"
        onClick={() => {
          setIsOpen(true);
          setShow(true);
        }}
      />
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        className="p-6 bg-white rounded-lg max-w-[550px]"
      >
        <div id="quick-search-container" ref={scriptContainerRef}>
          {show && (
            <PrimaryButton
              id="openSearchBox"
              onClick={() => {
                addScript();
                setShow(false);
              }}
              title="Quick Search"
              className="max-w-[200px] mx-auto"
            />
          )}
        </div>
      </Modal>
    </>
  );
};
