import { createBrowserRouter } from "react-router-dom";
import { AboutUs, ContactUs, Home, Propertis, Root } from "./routes";
import ErrorPage from "./error-page";
import { routes } from "./utils/contants";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      {
        path: routes.properties,
        element: <Propertis />,
      },
      {
        path: routes.contactUs,
        element: <ContactUs />,
      },
      {
        path: routes.aboutUs,
        element: <AboutUs />,
      },
    ],
  },
]);

export default router;
