import { RouterProvider } from "react-router-dom";
import router from "./router";
import { useEffect } from "react";
import emailjs from "@emailjs/browser";

const App = () => {
  useEffect(() => emailjs.init("2qKuS5OoWboHNmMoq"), []);
  return <RouterProvider router={router} />;
};

export default App;
