import React, { useState } from "react";
import { PrimaryButton } from "../../common";
import { NavLink } from "react-router-dom";
import { QuickSearchModal } from "../QuickSearchModal";

export const HomeSearch = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="flex-1 max-w-[580px]">
        <h1 className="text-xl sm:text-4xl uppercase text-white text-center md:text-left ">
          I AM THE KEY TO ALL YOUR REAL ESTATE NEEDS
        </h1>
        <p className="text-sm sm:text-base tracking-wider  text-white my-5 text-center md:text-left">
          Imagination Farms, Davie, Plantation Acres, Botaniko Weston, Pembroke
          Pines, Silver Lakes & More
        </p>
        {/* <NavLink
        to="https://jeansamuelluxama.idxbroker.com/idx/search/basic"
        target="_blank"
        className="flex justify-center md:justify-start"
      > */}

        {/* </NavLink> */}

        <QuickSearchModal isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </>
  );
};
