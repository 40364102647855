import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/layout";
import { PropertiesList, PropertiesSearch } from "../../components/properties";
import { Pagination, PrimaryButton } from "../../components/common";

export const Propertis = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.charset = "UTF-8";
    script.type = "text/javascript";
    script.id = "idxwidgetsrc-52656";
    script.src = "//jeansamuelluxama.idxbroker.com/idx/widgets/52656";

    const listings = document.getElementById("listings");

    // Append the script to the listings container
    if (listings) {
      listings.appendChild(script);
    }

    // Clean up by removing the script when the component unmounts
    return () => {
      if (listings) {
        listings.removeChild(script);
      }
    };
  }, []);
  return (
    <>
      <section className="bg-white border-b">
        <div className="resContainer">
          <Navbar linksStyles="!text-gray-700" />
        </div>
      </section>
      <section className="py-12 bg-bgLight">
        <div className="resContainer">
          <div className="flex justify-between items-center">
            <h1 className="text-xl sm:text-4xl uppercase text-gray-700 font-bold text-center md:text-left ">
              Properties
            </h1>
            <a
              href="https://jeansamuelluxama.idxbroker.com/idx/map/mapsearch"
              target="_blank"
              rel="noreferrer"
            >
              <PrimaryButton
                title="Search"
                className="min-w-[100px] rounded-full"
              />
            </a>
          </div>

          <div id="listings" className="mt-10"></div>
        </div>
      </section>
    </>
  );
};
