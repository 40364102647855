import emailjs from "@emailjs/browser";

export const sendMail = async (payload, setSuccessMessage) => {
  const serviceId = "service_7l1k4qk";
  const templateId = "template_hwp8o0i";
  try {
    await emailjs.send(serviceId, templateId, {
      ...payload,
    });
    setSuccessMessage(
      "Thanks for your message. We have received your message and will get back to you soon"
    );
    setTimeout(() => {
      setSuccessMessage(null);
    }, 5000);
  } catch (error) {
    console.log(error);
  }
};
