"use client";

import React, { useEffect } from "react";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";
import { PrimaryButton } from "../../common";
import PropertyCard from "../../common/PropertyCard";

export const HomeRecentProperties = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.charset = "UTF-8";
    script.type = "text/javascript";
    script.id = "idxwidgetsrc-51063";
    script.src = "//jeansamuelluxama.idxbroker.com/idx/widgets/51063";

    const listings = document.getElementById("featured-listings");

    // Append the script to the listings container
    if (listings) {
      listings.appendChild(script);
    }

    // Clean up by removing the script when the component unmounts
    return () => {
      if (listings) {
        listings.removeChild(script);
      }
    };
  }, []);

  return (
    <section className={"py-12 lg:py-20 bg-white"}>
      <div className="resContainer">
        <p
          className={
            "text-sm sm:text-base text-primary font-semibold mt-1 text-center"
          }
        >
          Featured
        </p>
        <h1
          className={
            "text-[22px] sm:text-[28px] uppercase md:text-[40px] font-normal text-dark text-center"
          }
        >
          Properties
        </h1>
        <p className={"text-sm sm:text-base text-gray-700 mt-2 text-center"}>
          Check out Some of our featured listings.
        </p>
      </div>

      <div className="">
        <div className="mt-12 " id="featured-listings">
          {/* <Slider {...sliderSettings} className="px-2 sm:px-0">
            <PropertyCard className="px-4 py-4" />
            <PropertyCard className="px-4 py-4" />
            <PropertyCard className="px-4 py-4" />
            <PropertyCard className="px-4 py-4" />
            <PropertyCard className="px-4 py-4" />
            <PropertyCard className="px-4 py-4" />
          </Slider> */}
        </div>
        <a
          href="https://jeansamuelluxama.idxbroker.com/idx/featured"
          target="_blank"
          rel="noreferrer"
        >
          <div className={"flex justify-center mt-12 max-w-[250px] mx-auto"}>
            <PrimaryButton
              title="Browse more properties"
              className="h-[46px]"
            />
          </div>
        </a>
      </div>
    </section>
  );
};

// Next Arrow icon Component
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className={
        "flex justify-center items-center sm:border border-secondary rounded-full w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] absolute -right-2 lg:-right-16 md:-right-10 top-1/2 translate-y-[-50%] cursor-pointer"
      }
      onClick={onClick}
    >
      <FaAnglesRight className="text-sm sm:text-lg text-secondary" />
    </div>
  );
};

// Previous Arrow icon Component
const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className={
        "flex justify-center items-center sm:border border-secondary rounded-full w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] absolute -left-2 lg:-left-16 md:-left-10 top-1/2 translate-y-[-50%] cursor-pointer z-[99]"
      }
      onClick={onClick}
    >
      <FaAnglesLeft className="text-sm sm:text-lg text-secondary" />
    </div>
  );
};
