import { routes } from "./contants";

export const menu = [
  {
    name: "Home",
    path: routes.home,
  },
  {
    name: "Properties",
    path: routes.properties,
  },
  {
    name: "Find a property",
    path: routes.FindProperty,
  },
  {
    name: "About",
    path: routes.aboutUs,
  },
  {
    name: "Contact",
    path: routes.contactUs,
  },
];
export const footerLinks = [
  {
    name: "Properties",
    path: routes.properties,
  },
  {
    name: "About",
    path: routes.aboutUs,
  },
  {
    name: "Contact",
    path: routes.contactUs,
  },
];
