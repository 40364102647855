import React from "react";

export const TextAreaField = ({ rows, placeholder, label, ...rest }) => {
  return (
    <div className="w-full">
      {label && (
        <label className="block text-gray-700 font-semibold mb-2 text-[15px]">
          {label}
        </label>
      )}
      <textarea
        rows={rows ?? 3}
        placeholder={placeholder}
        className="w-full py-3 focus:outline-none text-sm px-3 border mb-4 resize-none"
        {...rest}
      />
    </div>
  );
};
