import React from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "../../components/layout/Footer";

export const Root = () => (
  <>
    <Outlet />
    <Footer />
  </>
);
