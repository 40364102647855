import React, { useState } from "react";
import { InputField, PrimaryButton, TextAreaField } from "../../common";
import { sendMail } from "../../../utils/sendMail";

export const HomeUserInfoBox = () => {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [payload, setPayload] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  // Send message handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    await sendMail(payload, setSuccessMessage);
    setLoading(false);
    setPayload({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  // Handle Change function
  const handleChange = (e) => {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <section className="w-[410px] max-w-[100%] bg-white px-7 py-5">
      <h1 className="text-2xl font-bold">Let Us Call You!</h1>
      <p className="text-black/70 text-sm">To help you choose your property</p>
      <form className="mt-5" onSubmit={handleSubmit}>
        <div className="flex gap-x-4">
          <InputField
            placeholder="Your Name"
            required
            name="name"
            value={payload.name}
            onChange={handleChange}
          />
          <InputField
            placeholder="Your Number"
            required
            name="phone"
            value={payload.phone}
            onChange={handleChange}
          />
        </div>
        <InputField
          type="email"
          placeholder="Your Email"
          required
          name="email"
          value={payload.email}
          onChange={handleChange}
        />

        <TextAreaField
          rows={5}
          placeholder="Tell us about desired property"
          required
          name="message"
          value={payload.message}
          onChange={handleChange}
        />
        {successMessage && (
          <p className="text-green-500 text-sm font-medium text-center">
            {successMessage}
          </p>
        )}
        <PrimaryButton
          title="Submit"
          className="mt-3"
          type="submit"
          loading={loading}
          disabled={loading}
        />
      </form>
    </section>
  );
};
