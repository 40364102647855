import React from "react";
import { Statistics } from "./Statistics";
import { mImages } from "../../../assets/images";
import { NavLink } from "react-router-dom";
import { routes } from "../../../utils/contants";

export const StatisticsAndPropertyTypes = () => (
  <section className="bg-transparent py-12 md:py-20">
    <div className="resContainer">
      <img
        src={mImages.headerLogo}
        alt=""
        width={220}
        height={150}
        className="block mx-auto w-[140px] h-[90px] md:w-[220px] md:h-[150px]"
      />
      <h1 className="text-xl sm:text-2xl text-center uppercase font-bold mt-5 md:mt-7 ">
        Fidelity. Timeliness. Honesty. Esteem. Affection. Enjoyment. Compassion.
      </h1>
      <Statistics />
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:grid-cols-3">
        <NavLink
          to={"https://jeansamuelluxama.idxbroker.com/idx/map/mapsearch"}
          target="_blank"
        >
          <Card title={"Buy"} image={mImages.buy} path={""} />
        </NavLink>
        <NavLink
          to={"https://jeansamuelluxama.idxbroker.com/idx/search/advanced"}
          target="_blank"
        >
          <Card title={"Rent"} image={mImages.rent} path={""} />
        </NavLink>
        <NavLink to={routes.contactUs}>
          <Card title={"Sell"} image={mImages.sell} />
        </NavLink>
      </div>
    </div>
  </section>
);

// Card
const Card = ({ title, image, path }) => (
  <div className="w-full h-[320px] relative group overflow-hidden ">
    <img
      src={image}
      alt="Buy"
      className="w-full h-full object-cover group-hover:scale-110 transition-all duration-700"
    />
    <div className="w-full px-5 absolute bottom-0 left-0 bg-gradient-to-t from-black/50 to-transparent">
      <div className="w-full border-b pb-5 group-hover:-translate-y-10 transition-all duration-500">
        <h2 className="text-3xl font-medium text-white uppercase">{title}</h2>
      </div>
    </div>
  </div>
);
