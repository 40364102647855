import React from "react";
import { ButtonLoader } from "../ButtonLoader";

export const PrimaryButton = ({
  title,
  loading = false,
  className,
  ...rest
}) => (
  <button
    className={`${className} w-full h-[38px] sm:h-[42px] bg-primary text-white text-sm font-medium flex justify-center items-center`}
    {...rest}
  >
    {loading ? <ButtonLoader /> : title}
  </button>
);
