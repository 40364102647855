import React from "react";
import { Testimonial } from "../Testimonial";
import Slider from "react-slick";

export const TestimonialsAboutUs = () => {
  const sliderSettings = {
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    draggable: true,
    arrows: false,
    pauseOnHover: true,
  };
  return (
    <section className="my-8 sm:my-12 md:my-20">
      <h1 className="text-lg sm:text-3xl uppercase text-gray-700 font-bold text-center">
        What they say about us
      </h1>

      <div className="mt-10 testimonials__slider">
        <Slider {...sliderSettings}>
          {Array.from({ length: 5 }).map((_, index) => (
            <Testimonial key={index} />
          ))}
        </Slider>
      </div>
    </section>
  );
};
