import React, { useState } from "react";
import { InputField, PrimaryButton } from "../../common";

const MortgageCalculator = () => {
  const [principal, setPrincipal] = useState(0);
  const [downPayment, setDownPayment] = useState(0);
  const [interestRate, setInterestRate] = useState(0);
  const [years, setYears] = useState(0);
  const [monthlyPayment, setMonthlyPayment] = useState(0);

  // Function to Calculate monthly Installment Amount
  const calculateMonthlyPayment = (
    principal,
    downPayment,
    interestRate,
    years
  ) => {
    const loanAmount = principal - downPayment;
    const monthlyRate = interestRate / 100 / 12;
    const numberOfPayments = years * 12;
    const payment =
      (loanAmount * monthlyRate) /
      (1 - Math.pow(1 + monthlyRate, -numberOfPayments));

    return payment.toFixed(2);
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    const payment = calculateMonthlyPayment(
      principal,
      downPayment,
      interestRate,
      years
    );
    setMonthlyPayment(payment);
  };

  return (
    <div className="min-h-screen flex flex-wrap-reverse gap-y-8 items-center gap-x-12 md:gap-x-32 justify-center bg-bgLight py-8 px-4">
      <div className="max-w-lg w-full">
        <h2 className="text-xl font-bold mb-6 text-primary">
          Mortgage Calculator
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <InputField
            label="Principal Amount ($)"
            type="number"
            min={0}
            required
            value={principal}
            onChange={(e) => setPrincipal(e.target.value)}
          />
          <InputField
            label="Down Payment ($)"
            type="number"
            min={0}
            required
            value={downPayment}
            onChange={(e) => setDownPayment(e.target.value)}
          />

          <InputField
            label="Annual Interest Rate (%)"
            type="number"
            min={0}
            required
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
          />
          <InputField
            label="Loan Term (Years)"
            type="number"
            min={0}
            required
            value={years}
            onChange={(e) => setYears(e.target.value)}
          />
          <PrimaryButton
            title="Calculate"
            type="submit"
            className="min-h-[47px]"
          />
        </form>
      </div>
      <div className="w-[190px] h-[190px] md:w-[250px] md:h-[250px] border border-gray-300 rounded-full p-2 md:p-4">
        <div className="w-full h-full border-[7px] border-secondary rounded-full flex justify-center items-center flex-col gap-y-2">
          <h3 className="text-2xl font-semibold text-gray-800 text-center">
            <span className="text-primary">${monthlyPayment}</span>
            <span className="block text-sm md:text-base text-black/50">
              Monthly Payment
            </span>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default MortgageCalculator;
