import React from "react";

export const Testimonial = () => (
  <div className="max-w-[650px] mx-auto mb-10">
    <p className="text-sm sm:text-base text-gray-700 sm:leading-7 text-center">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas faucibus
      mollis interdum. Cum sociis natoque penatibus et magnis dis parturient
      montes, nascetur ridiculus mus. Curabitur blandit tempus porttitor.
      Maecenas faucibus mollis interdum.
    </p>
    <div className="flex flex-col items-center mt-7 md:mt-12">
      <div className="w-[110px] h-[110px] rounded-full overflow-hidden">
        <img
          src="https://images.pexels.com/photos/7681357/pexels-photo-7681357.jpeg"
          alt="Agent"
          className="w-full h-full object-cover"
        />
      </div>
      <h5 className="text-lg sm:text-xl text-gray-700 font-bold text-center mt-6">
        James Anderson
      </h5>
      <p className="text-xs text-gray-700 mt-1">CEO, Alpha</p>
    </div>
  </div>
);
