import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa6";
import { NavLink } from "react-router-dom";

export const Socials = () => (
  <div className="flex gap-x-4 items-center">
    <NavLink to="#">
      <FaFacebook className="text-gray-500 text-xl hover:text-blue-500" />
    </NavLink>
    <NavLink to="#">
      <FaTwitter className="text-gray-500 text-xl hover:text-blue-400" />
    </NavLink>
    <NavLink to="#">
      <FaInstagram className="text-gray-500 text-xl hover:text-purple-600" />
    </NavLink>
    <NavLink to="#">
      <FaLinkedin className="text-gray-500 text-xl hover:text-blue-500" />
    </NavLink>
  </div>
);
