import React from "react";
import { mImages } from "../../assets/images";
import { Navbar } from "../../components/layout";
import {
  HomeCommunitiesBlock,
  HomeRecentProperties,
  HomeSearch,
  HomeUserInfoBox,
  StatisticsAndPropertyTypes,
} from "../../components/home";
import MortgageCalculator from "../../components/home/MortageCalculator";

export const Home = () => {
  return (
    <>
      <header
        className={`w-full md:min-h-screen bg-cover md:bg-fixed bg-center bg bg-no-repeat before:contents-[] before:w-full before:h-full before:absolute before:top-0 before:bg-black/70 before:z-0 pb-5 md:pb-0`}
        style={{ backgroundImage: `url(${mImages.homeHeaderBg})` }}
      >
        <div className="z-10 relative min-h-screen">
          <div className="resContainer h-full min-h-screen flex flex-col">
            <Navbar />
            <div className="flex justify-between flex-col md:flex-row gap-5 items-center mt-8 md:mt-20">
              <HomeSearch />
              <HomeUserInfoBox />
            </div>
          </div>
        </div>
      </header>
      <StatisticsAndPropertyTypes />
      <HomeRecentProperties />
      <HomeCommunitiesBlock />
      <MortgageCalculator />
    </>
  );
};
