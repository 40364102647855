import React from "react";

export const InputField = ({ label, type, placeholder, ...rest }) => {
  return (
    <div className="w-full">
      {label && (
        <label className="block text-gray-700 font-semibold mb-2 text-[15px]">
          {label}
        </label>
      )}
      <input
        type={type ?? "text"}
        placeholder={placeholder}
        className="w-full h-[43px] focus:outline-none text-sm rounded-md px-3 border mb-4"
        {...rest}
      />
    </div>
  );
};
