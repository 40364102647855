import React from "react";
import { Navbar } from "../../components/layout";
import { AboutUsDetails, TestimonialsAboutUs } from "../../components/about";

export const AboutUs = () => (
  <>
    <section className="bg-white border-b">
      <div className="resContainer">
        <Navbar linksStyles="!text-gray-700" />
      </div>
    </section>
    <section className="py-12 bg-bgLight">
      <div className="resContainer">
        <h1 className="text-xl sm:text-4xl uppercase text-gray-700 font-bold text-center md:text-left">
          MEET Jean Samuel Luxama
        </h1>

        <AboutUsDetails />
        <TestimonialsAboutUs />
      </div>
    </section>
  </>
);
