import React from "react";
import { mImages } from "../../../assets/images";
import { NavLink } from "react-router-dom";
import { routes } from "../../../utils/contants";
import { PrimaryButton } from "../../common/PrimaryButton";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail, MdLocalPhone } from "react-icons/md";

export const Footer = () => (
  <footer className="pt-10 pb-5 bg-gray-200 border-t border-gray-200">
    <div className="resContainer">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-6 gap-x-12">
        <div className="flex flex-col gap-5">
          <NavLink to={routes.home}>
            <img src={mImages.headerLogo} alt="Logo" width={140} />
          </NavLink>
          <p className="text-sm sm:text-base">
            Whether you’re buying a house for the first time or you’ve made the
            decision to upgrade, we’ll be there to make sure everything goes
            well. Working with our experts is an excellent investment in your
            future.
          </p>
          <NavLink to={routes.aboutUs}>
            <PrimaryButton title="About Us" className="max-w-[150px]" />
          </NavLink>
        </div>

        {/* Quick Information */}
        <div>
          <div>
            <h5 className="text-gray-700 text-2xl font-bold">Quick Info</h5>
            <span className="w-20 h-[2px] bg-primary block mt-2" />
          </div>
          <div className="flex flex-col gap-4 mt-10">
            <div className="flex gap-2">
              <FaLocationDot className="text-gray-700 text-xl" />
              <span className="text-sm sm:text-base text-gray-700">
                909 North Miami Beach Blvd Suite 301A
              </span>
            </div>
            <div className="flex gap-2">
              <MdEmail className="text-gray-700 text-xl" />
              <span className="text-sm sm:text-base text-gray-700">
                js.luxama@klemortgage.com
              </span>
            </div>
            <div className="flex gap-2">
              <MdLocalPhone className="text-gray-700 text-xl" />
              <span className="text-sm sm:text-base text-gray-700">
                (305) 755-2672
              </span>
            </div>
          </div>
          <div className="mt-5">
            <h5 className="text-base text-gray-700 font-bold">Work Hours :</h5>
            <p className="text-sm sm:text-base text-gray-700 mt-2">
              Mon - Fri : 9am - 5pm
            </p>
          </div>
        </div>

        {/* Gallery */}
        <div>
          <div>
            <h5 className="text-gray-700 text-2xl font-bold">Gallery</h5>
            <span className="w-20 h-[2px] bg-primary block mt-2" />
          </div>

          <div className="grid grid-cols-3 gap-2.5 mt-10">
            {Array.from({ length: 9 }).map((_, index) => (
              <img
                key={index}
                src="https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg"
                alt=""
                width={90}
                height={90}
                className="w-full h-[90px]"
              />
            ))}
          </div>
        </div>
        {/* <ul className="flex gap-x-6 flex-wrap gap-y-3 items-center">
          {footerLinks.map((item, index) => (
            <li key={index}>
              <NavLink to={item.path}>
                <span className="text-sm font-medium text-gray-700 hover:underline">
                  {item.name}
                </span>
              </NavLink>
            </li>
          ))}
        </ul>
        <Socials /> */}
      </div>
      <hr className="my-5 border-t border-gray-300" />
      <p className="text-gray-700 text-sm sm:text-base text-center">
        Copyright {new Date().getFullYear()} © All Right Reserved.
      </p>
    </div>
  </footer>
);
