import React from "react";
import { Navbar } from "../../components/layout";
import { MdLocalPhone, MdOutlineEmail } from "react-icons/md";
import { GrLocation } from "react-icons/gr";
import { ContactUsForm, MapLocation } from "../../components/contact";

export const ContactUs = () => (
  <>
    <section className="bg-white border-b">
      <div className="resContainer">
        <Navbar linksStyles="!text-gray-700" />
      </div>
    </section>
    <section className="py-8 md:py-12 bg-bgLight">
      <div className="resContainer">
        <h1 className="text-xl sm:text-4xl uppercase text-gray-700 font-bold text-center md:text-left">
          Reach Us
        </h1>

        {/* Form */}
        <div className="bg-white p-7 md:p-12 mt-8 grid grid-cols-1 sm:grid-cols-2 gap-7 lg:gap-x-16">
          <ContactUsForm />
          <div>
            <div className="flex gap-x-4 mb-5">
              <MdLocalPhone className="text-xl text-gray-500 mt-1" />
              <div>
                <p className="text-base text-gray-700 font-medium">Phone</p>
                <p className="text-sm text-gray-500 mt-1">+123-456-789</p>
              </div>
            </div>
            <div className="flex gap-x-4 mb-5">
              <MdOutlineEmail className="text-xl text-gray-500 mt-1" />
              <div>
                <p className="text-base text-gray-700 font-medium">Email</p>
                <p className="text-sm text-gray-500 mt-1">test@domain.com</p>
              </div>
            </div>
            <div className="flex gap-x-4 mb-5">
              <MdOutlineEmail className="text-xl text-gray-500 mt-1" />
              <div>
                <p className="text-base text-gray-700 font-medium">WhatsApp</p>
                <p className="text-sm text-gray-500 mt-1">+123-456-789</p>
              </div>
            </div>
            <div className="flex gap-x-4">
              <GrLocation className="text-xl text-gray-500 mt-1" />
              <div>
                <p className="text-base text-gray-700 font-medium">Address</p>
                <p className="text-sm text-gray-500 mt-1">
                  13th Street 47 W 13th St, New York, NY ...
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Map location */}
        <MapLocation />
      </div>
    </section>
  </>
);
